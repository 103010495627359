@tailwind base;
@tailwind components;
@tailwind utilities;

/* remove margin padding from all elemtns */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  }

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}


/* react crop */
/* .ReactCrop__modifiers {
  display: flex !important;
  max-height: 100%;
}

.ReactCrop__modifiers > div{
  display: flex;
  justify-content: center;
}

.ReactCrop__modifiers > div > img{
  max-height: 100%;
} */